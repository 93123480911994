/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

window.$ = window.jQuery = require("jquery");
require("../bootstrap");
require("slick-carousel");
require("./slicknav");

$(window).on("scroll", function () {
    var scrollTop = $(this).scrollTop();
    if (scrollTop > 60) {
        $("body").addClass("scrolled");
    } else {
        $("body").removeClass("scrolled");
    }
});

$(function () {
    $("html, body").removeClass("is-loading");
    setTimeout(() => {
        $(".loading-box").fadeOut(300, function () {
            $(this).remove();
        });
    }, 100);

    if ($("#home-hero").length > 0) {
        $("#home-hero").slick({ fade: true, arrows: true, rtl: false });
    }

    if ($("#blog-slider").length > 0) {
        $("#blog-slider").slick({
            slidesToShow: 3,
            arrows: true,
            dots: true,
            infinite: false,
            rtl: false,
            responsive: [
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 2,
                    },
                },
                {
                    breakpoint: 501,
                    settings: {
                        slidesToShow: 1,
                    },
                },
            ],
        });
    }

    if ($("#gallery-slider").length > 0) {
        $("#gallery-slider").slick({
            fade: false,
            arrows: true,
            slidesToShow: 1,
            speed: 1100,
            autoplay: true,
            autoplaySpeed: 2000,
            rtl: false,
            pauseOnDotsHover: false,
            responsive: [
                {
                    breakpoint: 768,
                    settings: {
                        arrows: false,
                    },
                },
            ],
        });
    }

    if ($("#about-slider").length > 0) {
        $("#about-slider").slick({
            slidesToShow: 1,
            arrows: false,
            rtl: false,
            fade: true,
        });

        $("#about-slider").on("afterChange", function (slick, currentSlide) {
            $(".about-list a").removeClass("active");
            let id = $("#about-slider .slick-current")
                .find(".about-box")
                .attr("data-id");
            $(".about-list").find(id).addClass("active");
        });

        $("body").on("click", "#about-slider .about-left", function () {
            $("#about-slider").slick("slickPrev");
        });

        $("body").on("click", "#about-slider .about-right", function () {
            $("#about-slider").slick("slickNext");
        });

        $("body").on("click", ".about-list a", function (e) {
            e.preventDefault();
            $(".about-list a").removeClass("active");
            $(this).addClass("active");
            let pos = parseInt($(this).attr("data-position"));
            $("#about-slider").slick("slickGoTo", pos, false);
            return false;
        });
    }

    var menu = $("ul#navigation");
    if (menu.length) {
        menu.slicknav({
            prependTo: ".mobile_menu",
            closedSymbol: "+",
            openedSymbol: "-",
        });
    }
});

$(function () {
    var $grid = $(".grid-gal").isotope({
        itemSelector: ".grid-item",
        percentPosition: true,
        masonry: {
            columnWidth: ".grid-sizer",
        },
    });

    $grid.imagesLoaded().progress(function () {
        $grid.isotope("layout");
    });

    $(".isotopeMenu a").click(function (e) {
        e.preventDefault();
        var t = $(this).attr("href");
        $grid.isotope({
            filter: t,
        });
    });
});

let blogNextUrl = "/get-blog";
window.loadBlog = () => {
    var container = $(".blog-container");
    let loader = $(".blog-loader img");
    let loadMoreBtn = $(".blog-loader button");
    loader.show();
    loadMoreBtn.hide();

    $.ajax({
        url: blogNextUrl,
        type: "GET",
        headers: {
            "X-CSRF-TOKEN": $("meta[name='csrf-token']").attr("content"),
        },
        success: function (result) {
            for (let i = 0; i < result.data.length; i++) {
                const item = result.data[i];
                container.append(
                    `
                    <div class="blog-item">
                        <a href="/blog/${item.id}">
                            <div class="img"></div>
                            <img src="/storage/${item.img}" alt="">
                        </a>
                        <div class="blog-text">
                            <div class="post-time">
                                ${item.author_en}
                                <span>${formateDate(item.created_at)}</span>
                            </div>
                            <h4>${item.title_en}</h4>
                        </div>
                    </div>
                    `
                );
            }

            //<p>${item.body_en.toString().substring(0, 250)}...
            // <a href="/blog/${item.id}">READ MORE</a>
            // </p>

            if (result.next_page_url) {
                loadMoreBtn.show();
                blogNextUrl = result.next_page_url;
            }

            loader.hide();
        },
        error: function (request, msg, error) {},
    });
};

let storiesNextUrl = "/get-stories";
window.loadStories = () => {
    var container = $(".ajax-container");
    let loader = $(".ajax-loader img");
    let loadMoreBtn = $(".ajax-loader button");
    loader.show();
    loadMoreBtn.hide();

    $.ajax({
        url: storiesNextUrl,
        type: "GET",
        headers: {
            "X-CSRF-TOKEN": $("meta[name='csrf-token']").attr("content"),
        },
        success: function (result) {
            for (let i = 0; i < result.data.length; i++) {
                const item = result.data[i];
                container.append(
                    `
                    <div class="story-item">
                        <div class="row">
                            <div class="col-sm-7">
                                <div class="box-contain">
                                    <span><i class="far fa-calendar"></i>
                                    ${formateDate(item.created_at)}</span>
                                    <span>${item.category_en}</span>
                                    <h3>${item.title_en}</h3>
                                    <div class="desc">
                                        ${item.body_en
                                            .toString()
                                            .substring(0, 250)}...
                                    </div>
                                    <a href="/story/${item.id}">${$(
                        "#read-txt"
                    ).text()}</a>
                                </div>
                            </div>
                            <div class="col-sm-1"></div>
                            <div class="col-sm-4">
                                <img src="/storage/${item.img}" alt="">
                            </div>
                        </div>
                    </div>
                    `
                );
            }

            if (result.next_page_url) {
                loadMoreBtn.show();
                storiesNextUrl = result.next_page_url;
            }

            loader.hide();
        },
        error: function (request, msg, error) {},
    });
};

let eventsNextUrl = "/get-events";
window.loadEvents = () => {
    var container = $(".ajax-container");
    let loader = $(".ajax-loader img");
    let loadMoreBtn = $(".ajax-loader button");
    loader.show();
    loadMoreBtn.hide();

    $.ajax({
        url: eventsNextUrl,
        type: "GET",
        headers: {
            "X-CSRF-TOKEN": $("meta[name='csrf-token']").attr("content"),
        },
        success: function (result) {
            for (let i = 0; i < result.data.length; i++) {
                const item = result.data[i];
                container.append(
                    `
                    <a href="/event/${item.id}" class="event-item">
                        <div class="row">
                            <div class="col-sm-4">
                                <img src="/storage/${item.img}" alt="">
                            </div>
                            <div class="col-sm-4">
                                <div class="box-contain">
                                    <span><i class="far fa-calendar"></i>
                                    ${formateDate(item.date)}</span>
                                    <h3>${item.title_en}</h3>
                                </div>
                            </div>
                            <div class="col-sm-4">
                                <h4>${item.keyword_en}</h4>
                                <div class="desc">
                                ${item.body_en.toString().substring(0, 250)}...
                                </div>
                            </div>
                        </div>
                    </a>
                    `
                );
            }

            if (result.next_page_url) {
                loadMoreBtn.show();
                eventsNextUrl = result.next_page_url;
            }

            loader.hide();
        },
        error: function (request, msg, error) {},
    });
};

window.formateDate = (d) => {
    let date = new Date(d).toLocaleDateString("en-us", {
        day: "numeric",
    });
    let month = new Date(d).toLocaleDateString("en-us", {
        month: "long",
    });
    let year = new Date(d).toLocaleDateString("en-us", {
        year: "numeric",
    });
    return `${date} ${month} ${year}`;
};

$("body").on("submit", "#contact-form", function (e) {
    var btn = $(this).find("button");
    var inputs = $(this).find("input, textarea");
    btn.prop("disabled", true);

    e.preventDefault();

    var form = $(this);
    var url = form.attr("action");
    console.log(form.serialize());
    $.ajax({
        type: "POST",
        url: url,
        data: form.serialize(),
        success: function (data) {
            console.log(data);
            $("#thanks-contact-modal").modal("show");
            btn.prop("disabled", false);
            inputs.val("");
        },
    });
});
